import React from 'react';
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ContactForm from "../../components/ContactForm";

const breadCrumbLevels = {
  Home: "/",
  "Über mich": "/de/ueber-mich",
  "Google Tag Manager Consultant": "/de/google-tag-manager-consultant"
};

// Hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/google-tag-manager-consultant"
  },
  {
    hreflang: "de",
    href: "/de/google-tag-manager-consultant"
  },
  {
    hreflang: "x-default",
    href: "/en/google-tag-manager-consultant"
  }
];

const GoogleTagManagerConsultant = props => {


  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Freelance Google Tag Manager Consultant - M. Kupperschmidt"
          description="Profil und Kontakt von Matthias Kupperschmidt - Freiberufler & Freelance Google Tag Manager Consultant für Webanalytics and digitale Marketingdaten."
          lang="de"
          canonical="/de/google-tag-manager-consultant"
          image="tag-management-consultant-hero"
          alternateLangs={alternateLangs}
        />
        <MainContent article>
          <Img
            src="tag-manager-consultant/matthias-kupperschmidt-presenting-at-founders-house-oct-2019.jpg"
            alt="Google Tag Manager freelance Consultant Matthias Kupperschmidt präsentiert beim SEO Meetup Kopenhagen, October 2019"
          />
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
          <H as="h1">Google Tag Manager Consultant</H>
          <ProfileCard
            tags={["Tracking Setup", "Retargeting", "Ecommerce Tracking", "Google Analytics", "Custom Event Tracking", "Cookie Consent", "JavaScript", "React", "Python", "EN, DE, DA"]}
            profession="Google Tag Manager Consultant"
            rate={`${hourlyRate}€/Stunde`}
            location="remote aus Kopenhagen, DK"
            cta="Kontakt"
            alt="GTM Experte"
          />
          <p>
            Mit Tag Management Systemen ist es möglich mit minimalstem Geld- und Zeitaufwand eine Vielzahl von Tags
            (Services) auf einer Website zu implementieren. Google Tag Manager (GTM) ist das populärste,
            meistverbreitete Tag Management System.
          </p>
          <p>
            Ich bin Google Tag Manager Consultant und plane und implementiere <Link to="/de/tag-management">Tag Management Systeme</Link> für meine Kunden.
          </p>
          <p>
            Ich implementiere alle benötigten Tags flexibel und skalierbar über beliebig viele Domains oder
            Sprachversionen einer Webseite.
          </p>
          <p>
            Event Tracking für Registrierungen auf der neuen Landingpage oder Conversions für Google Ads: Beides kann
            umgehend ohne Entwickler auf der Webseite implementiert werden. In welchem Umfang Daten den Tags zugänglich
            gemacht werden, ist dabei frei konfigurierbar.
          </p>

          <br />
          <br />
          <H as="h6" style={{ "textAlign": "center" }}>Bisherige Kunden</H>
          <ImgScreenshot
            src="about/clients_700px.png"
            alt="clients I worked with"
            className="article-img"
          />
          <br />
          <br />
          <br />
          <H as="h2" style={{ "textAlign": "center" }}>Angebot anfordern</H>
          {/* todo */}
          <ContactForm showHeadline={false} formName="ga consultant (DE) - contact form" />


          <H as="h2">Leistungen</H>
          <p>Als freelance GTM Consultant biete ich die selben Dienstleistungen, wie die digitale Agentur.</p>
          <p>
            Du sparst Zeit und Geld, da du direkt mit dem Experten kommunizierst und typisches Projekt Management und
            Verwaltungsaufgaben einer Agentur entfallen.
          </p>
          <p>Die Leistungen meiner GTM Beratung umfassen:</p>
          <ul>
            <li>
              Planen, Implementieren und{" "}
              <Link to="/de/google-analytics-einrichten">Einrichten von Google Analytics</Link>
            </li>
            <li>Koordination von Media- und Webagenturen</li>
            <li><Link to="/de/event-tracking">Event Tracking</Link></li>
            <li>Cookiebanner Implementierung</li>
            <li>Datenintegration mit Werbepartnern</li>
            <li>Retargeting mit Facebbok, Google Ads, Linkedin usw.</li>
            <li>
              <Link to="/de/data-layer#data-layer-implementieren">Data Layer Implementierung</Link>
            </li>
            <li>Systeme zum Tracken von Affiliate Partnern</li>
            <li>Video tracking</li>
            <li>Datenimport oder Datenexport</li>
            <li>JavaScript Lösungen und Debugging</li>
          </ul>
          <p>
            Alles das und mehr kann durch Tag Management Systeme flexibel und einfach konfiguriert werden, ohne dass ein
            Entwickler den Quellcode ändern muss. Durch die Geschwindigkeit und Flexibilität der Implementierung haben
            sich Tag Management Systeme wie Google Tag Manager als Standard für das Verwalten von Tags und Data
            Management etabliert. Marketer von kleinen bis großen Unternehmen können somit digitale Kampagnen schnell
            lancieren und den Erfolg messen - eine Flexibiltät die der Markt lange vermisst hat.
          </p>

          <H as="h2">Was ist Google Tag Manager?</H>
          <p>
            Google Tag Manager ist ein Tool mit dem Tags auf Webseiten implementiert werden können. Außerdem können
            Regeln für die Ausführung der Tags bequem in einem grafischen User Interface erstellt werden, anstatt im
            Code der Webseite. Google Tag Manager ermöglicht es mittels einem <Link to="/de/wiki-analytics/container-tag-de">Container Tag</Link> mehrere andere Tags in sekundenschnelle auf der Webseite zu
            publizieren.
          </p>
          <p>
            Durch diese Flexibilität wird GTM meist für Online-Marketing und Datenerhebung genutzt. Tracking Setups mit
            Google Analytics, Datenintegration mit Werbepartnern (z.B. Facebook, Google Ads etc.) oder
            Affiliatemarketing sind typische Anwendungsbereiche.
          </p>
          <p>
            Falls Du mehr wissen willst, lies mehr in der ausführlichen{" "}
            <Link to="/de/was-ist-google-tag-manager">Erklärung was Google Tag Manager ist</Link>.
          </p>
          <H as="h2">Was sind die Vorteile von Google Tag Manager?</H>
          <p>
            Der größte Vorteil sind die schnellere und anpassungsfähigere Implementierung von Tags und ein skalierbares
            Datenmanagement zwischen allen Services. Das spart Zeit und Arbeitsaufwand, da nicht jedes Mal der Quelltext
            der Seite bearbeitet werden muss. Programmierer sind hochbezahlt und rar gesät, daher lohnt es sich Tracking
            und Tag-Implementierungen in ein Tool mit einem grafischem User Interface auszulagern. Lies mehr über{" "}
            <Link to="/de/google-tag-manager-vorteile">Google Tag Managers Vorteile</Link> für mehr Informationen.
          </p>

          <H as="h2">Kosten für Google Tag Manager Consulting</H>
          <p>
            Mein Stundensatz ist {hourlyRate}€ pro Stunde. Wenn z.B. ein Tracking System für eine Webseite erstellt
            werden soll, wird das Projekt abgeschätzt und ein Angebot gegeben.
          </p>
          <p>
            Abhängig von der Größe und Technologie einer Webseite, kostet ein komplettes Tag Management System mit
            Google Analytics Setup und Sondereinstellungen zwischen 1800€ und 6000€ netto.
          </p>
          <p>
            Falls Du eine große Webseite besitzt und ständigen Support benötigst, kann ein monatlicher Retainer für eine
            Mindestanzahl von 10 Stunden vereinbart werden.
          </p>
          <p>Kleinere Aufgaben werden pauschal berechnet und liegen zwischen 100€ und 300€.</p>

          <H as="h2">Mein Profil</H>
          <p>
            Ich habe seit 2011 in digitalen Agenturen gearbeitet und mich seit 2014 auf SEO und Tracking mit Google
            Analytics spezialisiert. Ich habe Tracking Setups für große eCommerce Webseiten, Supermarktketten,
            Autohersteller und Versicherungsunternehmen gebaut und in Stand gehalten und mit den jeweiligen Webbüros und
            Mediaagenturen meiner Kunden zusammengearbeitet.
          </p>
          <p>
            Meine Kunden sind meistens Enterprises oder größere Unternehmen, bzw. entsprechen Ihnen in ihrer Struktur
            und den Prozessen.
          </p>
          <p>
            Seit 2018 arbeite ich selbstständig als Consultant für Google Tag Manager - nur eben als Freelancer. Ich bin
            in meiner Persönlichkeit sehr strukturiert, rational und detailliert. Der Schritt raus aus dem hektischen
            Agenturumfeld war daher eine logischer nächster Schritt.
          </p>
          <p>
            Um mein Know-How auf aktuellem Stand zu halten, besuche ich jährlich einige Web Analytics Konferenzen
            (Measurecamp) und mitorganisiere das Kopenhagener SEO Meetup.
          </p>
          <H as="h2">Suchst Du einen GTM Freelancer?</H>
          <p>
            Schreib mir dein Anliegen über mein <Link to="/de/kontakt">Kontaktformular</Link>. Ich melde mich dann für
            gewöhnlich in 1-2 Tagen zurück.
          </p>
        </MainContent>
      </React.Fragment>

    </Layout>
  );
}

export default GoogleTagManagerConsultant;
